<template>
  <div class="notification" v-if="isShow">
    <div class="notification__content" v-html="message"></div>
    <button class="notification__close" @click="close">
      <i class="icon-close"></i>
    </button>
  </div>
</template>

<script>
export default {
  name: "Notification",
  data: () => {
    return {
      isShow: true,
    };
  },
  props: {
    message: {
      type: String,
      default: "",
    },
  },
  methods: {
    close() {
      this.isShow = !this.isShow;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/vars";
@import "@/assets/styles/mixins";
.notification {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid rgba(230, 157, 91, 0.2);
  background: rgba(230, 157, 91, 0.1);
  padding: 12px 20px 12px 96px;
  @media screen and (max-width: 740px) {
    padding: 20px 28px;
    align-items: flex-start;
  }
  &__content {
    max-width: 860px;
    text-align: left;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    color: rgba(31, 35, 37, 0.8);
    margin-right: 40px;
    @media screen and (max-width: 740px) {
      margin-right: 20px;
    }
    strong {
      font-weight: 500;
    }
  }
  &__close {
    width: 20px;
    height: 20px;
    display: inline-flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    background: $color-white;
    border-radius: 50%;
    i {
      font-size: 7px;
      line-height: 8px;
      color: $color-orange;
      transition: all 0.3s ease;
    }
    &:hover {
      i {
        color: darken($color-orange, 10);
      }
    }
  }
}
</style>
