<template>
  <div class="previous-feedback">
    <div class="previous-feedback__header">
      <div class="wrapper">
        <div class="cell">
          <div class="logo">
            <img class="logo__img" src="@/assets/img/logo_short.svg" />
          </div>

          <div class="title">Previous feedback</div>
        </div>
      </div>
    </div>
    <Notification
      :message="`Please note that it may take <strong>up to 72 hours</strong> for your latest feedback to be processed and shown here.`"
    />
    <div class="previous-feedback__body">
      <Loader v-if="isLoading" class="loader" />
      <PostList
        v-else
        :posts="postsList"
        :stories="storiesList"
        :postType="'client'"
        :isFacebookPlatform="isFacebookPlatform"
        :currentCustomer="clientData"
        :isShowSwitch="false"
        isShowRating
        isSortedByType
        isHeaderClient
        isPreviousFeedback
        isCanAddComment
        isShowSubmitBtn
        isShowEditIconForClient
      />
    </div>
  </div>
</template>

<script>
import PostList from "@/components/createPost/PostList";
import Notification from "@/components/global/Notification";
import { mapActions, mapGetters, mapMutations } from "vuex";
import Loader from "@/components/global/Loader";

export default {
  name: "PreviousFeedback",
  data: () => ({
    posts: [],
    postsLimit: 10,
    storiesLimit: 10,
    notIncludingTime: 50, //hours
    clientData: null,
    exportData: null,
    isLoading: true,
  }),
  components: {
    Loader,
    PostList,
    Notification,
  },
  computed: {
    postsList() {
      let filteredList = null;
      if (
        this.$dayjs
          .unix(this.exportData.exportedAt)
          .add(this.notIncludingTime, "hour")
          .unix() < this.$dayjs().unix()
      ) {
        filteredList = this.posts
          .filter((i) => i.type === "post")
          .sort((a, b) => (a.updatedAt < b.updatedAt ? 1 : -1));
      } else {
        filteredList = this.posts
          .filter((i) => i.type === "post")
          .sort((a, b) => (a.updatedAt < b.updatedAt ? 1 : -1));
        this.exportData.postsIdList.forEach(
          (id) => (filteredList = filteredList.filter((i) => i.id !== id))
        );
      }
      return filteredList.slice(0, this.storiesLimit);
    },
    storiesList() {
      let filteredList = null;
      if (
        this.$dayjs
          .unix(this.exportData.exportedAt)
          .add(this.notIncludingTime, "hour")
          .unix() < this.$dayjs().unix()
      ) {
        filteredList = this.posts
          .filter((i) => i.type === "story")
          .sort((a, b) => (a.updatedAt < b.updatedAt ? 1 : -1));
      } else {
        filteredList = this.posts
          .filter((i) => i.type === "story")
          .sort((a, b) => (a.updatedAt < b.updatedAt ? 1 : -1));
        this.exportData.postsIdList.forEach(
          (id) => (filteredList = filteredList.filter((i) => i.id !== id))
        );
      }
      return filteredList.slice(0, this.storiesLimit);
    },
    isFacebookPlatform() {
      return (
        this.clientData.social_platforms.length === 1 &&
        this.clientData.social_platforms.includes("facebook")
      );
    },
  },
  async created() {
    await this.getClientInfo();
  },
  methods: {
    ...mapActions("createPost", ["fetchPosts"]),
    ...mapActions("clientDashboard", ["fetchCustomerInfo", "getSmpostExport"]),
    ...mapMutations("clientSelection", ["setCurrentCustomer"]),
    async getClientInfo() {
      this.exportData = await this.getSmpostExport(this.$route.params.id);
      this.clientData = await this.fetchCustomerInfo(
        this.exportData.customerId
      );
      this.setCurrentCustomer(this.clientData);
      await this.getPosts();
    },
    async getPosts() {
      this.isLoading = true;

      await this.getArchivePosts();
      await this.getApprovedPosts();
      await this.getRejectedPosts();

      this.isLoading = false;
    },
    async getArchivePosts() {
      try {
        const data = await this.fetchPosts({
          routeName: "archive",
          customerId: this.exportData.customerId,
          limit: 10,
        });
        data.forEach((item) => {
          this.posts.push(item);
        });
      } catch (e) {
        console.log(e);
      }
    },
    async getApprovedPosts() {
      try {
        const data = await this.fetchPosts({
          routeName: "approved",
          customerId: this.exportData.customerId,
          limit: 10,
        });
        data.forEach((item) => {
          this.posts.push(item);
        });
      } catch (e) {
        console.log(e);
      }
    },
    async getRejectedPosts() {
      try {
        const data = await this.fetchPosts({
          routeName: "rejected",
          customerId: this.exportData.customerId,
          limit: 10,
        });
        data.forEach((item) => {
          this.posts.push(item);
        });
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/vars";
@import "@/assets/styles/mixins";

.previous-feedback {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 70px;
  &__header {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 18px 20px;
    background: $color-white;
    position: fixed;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    left: 0;
    right: 0;
    top: 0;
    z-index: 100;

    .wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .logo {
      width: 51px;
      height: 30px;
      flex-shrink: 0;
      margin-right: 25px;

      &__img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .info {
      display: inline-flex;
      align-items: center;
      margin-right: 24px;
      @media screen and (max-width: 920px) {
        flex-direction: column;
        align-items: flex-start;
      }

      .item {
        display: inline-flex;
        align-items: baseline;
        margin-left: 41px;
        position: relative;
        @media screen and (max-width: 920px) {
          margin-left: 0;
        }

        &:before {
          content: "";
          position: absolute;
          width: 1px;
          height: 22px;
          background: rgba(47, 72, 88, 0.1);
          left: -21px;
          top: 0;
          @media screen and (max-width: 920px) {
            display: none;
          }
        }

        &:first-child {
          margin-left: 0;

          &:before {
            display: none;
          }
        }

        &.time {
          @media screen and (max-width: 920px) {
            .item {
              &__title {
                font-size: 8px !important;
                line-height: 140% !important;
              }

              &__data {
                font-size: 8px !important;
                line-height: 140% !important;
              }
            }
          }
        }

        &__title {
          font-weight: 500;
          font-size: 12px;
          line-height: 140%;
          color: #515456;
          margin-right: 8px;
        }

        &__data {
          color: $color-orange;
          font-weight: 500;
          font-size: 14px;
          line-height: 140%;

          &.green {
            color: $color-green;
          }

          &.red {
            color: $color-red;
          }
        }
      }
    }

    .share-btn {
      height: 36px;
      padding: 0 16px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      color: $color-white;
      border-radius: 18px;
      cursor: pointer;
      background: $color-violet;
      transition: all 0.3s ease;

      &:hover {
        background: darken($color-violet, 10);
      }

      &:disabled {
        background: #eeeff0;
        color: $color-grey2;
        cursor: default;
      }
    }

    .title {
      font-weight: 700;
      font-size: 24px;
      line-height: 140%;
      color: $color-black;
      @media screen and (max-width: 920px) {
        font-size: 18px;
      }
    }

    .cell {
      display: flex;
      align-items: center;

      &:last-child {
        flex-shrink: 0;
      }
    }
  }
  &__body {
    background: #f3f2f3;
    flex: 1;
  }

  .loader {
    ::v-deep {
      position: fixed;
    }
  }

  ::v-deep {
    .post-list {
      width: 100%;
      &__item {
        display: flex;
        flex-direction: column;
        > div {
          display: flex;
          flex: 1;
        }
      }
    }
  }
  .loader {
    ::v-deep {
      position: fixed;
    }
  }
}
</style>
